export default {
  errors: {
    emailAlreadyRegistered:
      'E-Mail bereits registriert, bitte versuche dich einzuloggen.',
    invalidEmail: 'Ungültige E-Mail, bitte versuche es erneut.'
  },
  title: 'Registrieren',
  subtitle: 'Erstelle ein neues Vamoz-Konto',
  success: {
    title: 'Überprüfe dein E-Mail-Postfach ✉️',
    message:
      'Wir haben dir eine E-Mail mit einem Link zur Verifizierung deines Kontos gesendet. Es kann ein paar Minuten dauern bis diese ankommt. Bitte überprüfe auch deinen Spam-Ordner.'
  },
  goToLogin: 'Zum Login',
  button: 'Registrieren',
  allowedDomains: 'Erlaubte Domains: {domains}',
  help: 'Hilfe',
  instructions: {
    title: 'Anleitung zur Registrierung',
    subtitle:
      'Bitte folge diesen drei Schritten, um dich bei Vamoz zu registrieren.',
    step1: 'Gib deine E-Mail-Adresse ein (von einer erlaubten Domain)',
    step2: 'Überprüfe dein E-Mail-Postfach (kann ein paar Minuten dauern)',
    step3:
      'Klicke auf den Link in der E-Mail, um deine E-Mail-Adresse zu bestätigen',
    spamFolder:
      'Wenn du die E-Mail nicht in deinem Posteingang siehst, überprüfe bitte deinen Spam-Ordner.'
  }
};
