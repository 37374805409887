interface EnvironmentService {
  baseUrl: string;
  wwwUrl: string;
  rootDomain: string;
  recaptchaKey: string;
  intercomAppId: string;
}

const environmentService: EnvironmentService = {
  baseUrl: import.meta.env.VITE_BASE_URL as string,
  wwwUrl: import.meta.env.VITE_WWW_URL as string,
  rootDomain: import.meta.env.VITE_ROOT_DOMAIN as string,
  recaptchaKey: import.meta.env.VITE_RECAPTCHA_KEY as string,
  intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID as string
};

export default environmentService;
