import type { FC, DetailedHTMLProps, HTMLAttributes } from 'react';
import { createElement } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface HeadingProps {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const headingClasses = (type: HeadingProps['type'], customClasses: string) =>
  twMerge(
    clsx(
      'font-rubik',
      {
        'text-2xl font-semibold md:text-3xl': type === 'h1',
        'text-xl font-medium md:text-2xl': type === 'h2',
        'text-lg font-medium md:text-xl': type === 'h3',
        'text-base font-medium md:text-lg': type === 'h4'
      },
      customClasses
    )
  );

export const Heading: FC<
  DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> &
    HeadingProps
> = ({ children, type, tag, className: customClasses }) =>
  createElement(
    tag ?? type,
    { className: headingClasses(type, customClasses ?? '') },
    children
  );
