export default {
  errors: {
    sessionExpired: 'Your session has expired. Login again to continue.',
    magicLinkLoginDisabled:
      'Magic link login is disabled for your company. Please use SSO to login instead.',
    userNotFound:
      'This email is not registered with Vamoz. Please contact your company administrator to get access.'
  },
  title: {
    checkEmail: 'Check your email ✉️',
    emailConfirmed: 'Email confirmed!',
    welcome: 'Welcome to Vamoz!'
  },
  subtitle: {
    emailConfirmed:
      'Your email has been confirmed. You can now login to Vamoz.',
    enterEmail: 'Please enter your email to receive a secure login link.',
    emailSent:
      "We've sent you an email with a secure login link to login to Vamoz. The email may take a few minutes to arrive. Please also check your spam folder."
  },
  button: 'Login',
  dontHaveAccount: "Don't have an account yet?",
  conactUs: 'Contact us here'
};
