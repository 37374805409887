import environmentService from './environment.service';

export const login = async (
  email: string,
  recaptchaToken: string
): Promise<boolean> => {
  const response = await fetch(`${environmentService.baseUrl}/auth/v2/login`, {
    method: 'POST',
    body: JSON.stringify({ email, recaptcha_token: recaptchaToken }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok)
    throw new Error(
      response.status === 405
        ? 'magic link login disabled'
        : response.status === 404
        ? 'user not found'
        : undefined
    );

  return true;
};

export const signup = async (
  email: string,
  companyId: string,
  token: string,
  recaptchaToken: string
): Promise<number> => {
  try {
    const response = await fetch(
      `${environmentService.baseUrl}/core/v1/public/signup/user`,
      {
        method: 'POST',
        body: JSON.stringify({
          email: email.toLowerCase(),
          company_id: companyId,
          token,
          recaptcha_token: recaptchaToken
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );

    return response.status;
  } catch {
    throw new Error();
  }
};

export interface CompanyData {
  company_name: string;
  valid_domains: string[];
  welcome: string;
  description: string;
}

export const fetchCompanyData = async (
  companyId: string,
  token: string
): Promise<CompanyData> => {
  try {
    const url =
      `${environmentService.baseUrl}/core/v1/public/company/` +
      companyId +
      '/signup_info?token=' +
      token;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) throw new Error();

    return await response.json();
  } catch {
    throw new Error();
  }
};

export const preapproveCase = async (
  token: string,
  approvalStatus: string,
  recaptchaToken: string
): Promise<void> => {
  try {
    const response = await fetch(
      `${environmentService.baseUrl}/core/v1/public/case/preapprove`,
      {
        method: 'POST',
        body: JSON.stringify({
          token,
          status: approvalStatus,
          recaptcha_token: recaptchaToken
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.ok) throw new Error();
  } catch (e) {
    throw new Error();
  }
};
