import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import App from './App';
import './globals.css';
import environmentService from './services/environment.service';

const domElement = document.getElementById('root');

const oneMonth = 1000 * 60 * 60 * 24 * 30;

if (domElement) {
  createRoot(domElement).render(
    <React.StrictMode>
      <CookiesProvider
        defaultSetOptions={{
          path: '/',
          domain: environmentService.rootDomain,
          expires: new Date(Date.now() + oneMonth)
        }}>
        <App />
      </CookiesProvider>
    </React.StrictMode>
  );
}
