import { useIntl } from '@/intl';
import { Paragraph } from '../atoms';

interface StepProps {
  number: number;
  children: React.ReactNode;
}

const Step: React.FC<StepProps> = ({ number, children }) => (
  <div className='flex items-center gap-2'>
    <div className='flex h-7 w-7 shrink-0 items-center justify-center rounded-full bg-primary text-white'>
      {number}
    </div>
    <Paragraph>{children}</Paragraph>
  </div>
);

export const SignupInstructions: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className='flex flex-col gap-4 py-6'>
        <Step number={1}>
          {formatMessage({
            id: 'signup.instructions.step1'
          })}
        </Step>
        <Step number={2}>
          {formatMessage({
            id: 'signup.instructions.step2'
          })}
        </Step>
        <Step number={3}>
          {formatMessage({
            id: 'signup.instructions.step3'
          })}
        </Step>
      </div>

      <Paragraph>
        {formatMessage({
          id: 'signup.instructions.spamFolder'
        })}
      </Paragraph>
    </>
  );
};
