import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { Fragment } from 'react';
import { IoClose } from 'react-icons/io5';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useIntl } from '@/intl';
import { Button, Heading, Paragraph } from '../atoms';

interface ModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  unstyled?: boolean;
  centered?: boolean;
  title: string;
  subtitle?: string;
  titleTag?: ReactNode;
  cancelButton?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  onConfirm?: () => void;
  successMessage?: string;
  errorMessage?: string;
  confirmButtonVariant?: ComponentProps<typeof Button>['variant'];
  secondaryButton?: ReactNode;
  noAction?: boolean;
  showCloseButton?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  unstyled,
  centered,
  title,
  subtitle,
  titleTag,
  cancelButton = true,
  cancelButtonText,
  confirmButtonText,
  confirmButtonDisabled,
  onConfirm,
  successMessage,
  errorMessage,
  confirmButtonVariant,
  secondaryButton,
  noAction,
  showCloseButton = true
}) => {
  const { formatMessage } = useIntl();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' onClose={onClose} className='fixed inset-0 z-50'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-150'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-black/40 backdrop-blur-[2px]' />
        </Transition.Child>

        <div
          className={clsx(
            'fixed inset-x-0 flex -translate-y-1/2 justify-center',
            centered ? 'top-1/2' : 'top-1/2 sm:top-[40%]'
          )}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-150'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-150'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'>
            <Dialog.Panel
              as='div'
              className={clsx(
                !unstyled &&
                  'no-scrollbar relative flex max-h-[90vh] w-[550px] max-w-[94vw] flex-col gap-4 overflow-y-scroll rounded-xl bg-white p-6 text-left md:p-8'
              )}>
              <div className='flex items-center justify-between gap-4'>
                <div className='flex items-center gap-2'>
                  <Heading type='h4'>{title}</Heading>
                  {titleTag}
                </div>

                {showCloseButton && (
                  <button
                    onClick={onClose}
                    className='rounded-full bg-gray-100 p-1'>
                    <IoClose />
                  </button>
                )}
              </div>

              {subtitle && (
                <Paragraph variant='secondary'>{subtitle}</Paragraph>
              )}

              {children}

              {successMessage && (
                <div className='rounded-xl bg-green-500 p-4 text-white'>
                  {successMessage}
                </div>
              )}

              {errorMessage && (
                <div className='rounded-xl bg-red/80 p-4 text-white'>
                  {errorMessage}
                </div>
              )}

              {!noAction && (
                <div
                  className={clsx(
                    'flex items-center',
                    cancelButton || secondaryButton
                      ? 'justify-between'
                      : 'justify-end'
                  )}>
                  {secondaryButton ??
                    (cancelButton ? (
                      <Button variant='gray' onClick={onClose}>
                        {cancelButtonText ??
                          formatMessage({ id: 'shared.actions.cancel' })}
                      </Button>
                    ) : null)}

                  <Button
                    variant={confirmButtonVariant ?? 'primary'}
                    disabled={confirmButtonDisabled}
                    onClick={onConfirm}>
                    {confirmButtonText ??
                      formatMessage({ id: 'shared.actions.confirm' })}
                  </Button>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
