import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Heading,
  Paragraph,
  Spinner,
  StyledLink
} from '@/components/atoms';
import { TextInput } from '@/components/atoms/TextInput';
import { AuthForm } from '@/components/molecules';
import { useIntl } from '@/intl';
import { login } from '@/services/auth.service';

const Login: React.FC = () => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [email, setEmail] = useState(searchParams.get('email') ?? '');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  const queryError = searchParams.get('error');

  const handleLogin = useCallback(async () => {
    try {
      if (!executeRecaptcha) throw new Error('Recaptcha not loaded');

      const recaptchaToken = await executeRecaptcha('login');
      if (!recaptchaToken) throw new Error('Recaptcha token not found');

      setLoading(true);
      await login(email.toLowerCase(), recaptchaToken);
      setSuccess(true);
    } catch (e: any) {
      if (e?.message === 'user not found') {
        setError(formatMessage({ id: 'login.errors.userNotFound' }));
      } else if (e?.message === 'magic link login disabled') {
        setError(formatMessage({ id: 'login.errors.magicLinkLoginDisabled' }));
      } else {
        setError(formatMessage({ id: 'shared.errors.generic' }));
      }
    } finally {
      setLoading(false);
    }
  }, [email, executeRecaptcha]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email.length === 0) {
      setError(formatMessage({ id: 'shared.errors.invalidEmail' }));
      return;
    }
    void handleLogin();
  };

  const signupConfirmation = searchParams.get('mode') === 'signup';

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Heading type='h1'>
        {success
          ? formatMessage({ id: 'login.title.checkEmail' })
          : signupConfirmation
          ? formatMessage({ id: 'login.title.emailConfirmed' })
          : formatMessage({ id: 'login.title.welcome' })}
      </Heading>

      {queryError === 'session-expired' && !success && (
        <Paragraph className='!text-red'>
          {formatMessage({ id: 'login.errors.sessionExpired' })}
        </Paragraph>
      )}

      {signupConfirmation ? (
        <Paragraph>
          {formatMessage({ id: 'login.subtitle.emailConfirmed' })}
        </Paragraph>
      ) : !success ? (
        <Paragraph>
          {formatMessage({ id: 'login.subtitle.enterEmail' })}
        </Paragraph>
      ) : null}

      {loading ? (
        <div className='mx-auto pt-8'>
          <Spinner />
        </div>
      ) : success ? (
        <Paragraph>
          {formatMessage({ id: 'login.subtitle.emailSent' })}
        </Paragraph>
      ) : (
        <>
          <TextInput
            type='email'
            id='email'
            name='email'
            placeholder={formatMessage({
              id: 'shared.placeholders.companyEmail'
            })}
            value={email}
            error={!!error}
            onChange={e => {
              setEmail(e.target.value);
              if (error) setError(undefined);
            }}
          />

          {error && <Paragraph className='!text-red'>{error}</Paragraph>}

          <Button type='submit' variant='primary'>
            {formatMessage({ id: 'login.button' })}
          </Button>

          <Paragraph>
            {formatMessage({ id: 'login.dontHaveAccount' })}{' '}
            <StyledLink href='mailto:support@vamoz.io' variant='highlighted'>
              {formatMessage({ id: 'login.conactUs' })}
            </StyledLink>
          </Paragraph>
        </>
      )}
    </AuthForm>
  );
};

export default Login;
