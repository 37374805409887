const shortFormat = new Intl.DateTimeFormat('en', {
  day: 'numeric',
  month: 'short'
});

const longFormat = new Intl.DateTimeFormat('en', {
  day: 'numeric',
  month: 'short',
  year: 'numeric'
});

export const formatDate = (
  date: Date | string,
  mode?: 'short' | 'long'
): string => {
  try {
    if (!mode || mode === 'long') {
      return longFormat.format(new Date(date));
    } else {
      return shortFormat.format(new Date(date));
    }
  } catch (e) {
    return 'Invalid date';
  }
};

export const oneMonth = 1000 * 60 * 60 * 24 * 30;
