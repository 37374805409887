export default {
  locale: {
    en: 'Inglese',
    de: 'Tedesco'
  },
  placeholders: {
    companyEmail: 'johndoe@acme.com'
  },
  errors: {
    generic: 'Si è verificato un errore inaspettato, riprova.',
    invalidEmail: 'Si prega di fornire un indirizzo email valido'
  },
  actions: {
    cancel: 'Annulla',
    confirm: 'Conferma'
  }
};
