export default {
  title: 'Fall vorab genehmigen',
  reviewCase:
    'Bitte prüfe den Fall, wie in der entsprechenden E-Mail beschrieben, und klicke unten auf den Button, um den Fall entweder zu genehmigen oder abzulehnen.',
  description:
    '{employeeFirstName} {employeeLastName} beantragt eine Reise nach {country} vom {startDate} bis {endDate}.',
  thankYou:
    'Danke für deinen Beitrag! Du kannst dieses Fenster jetzt schließen.',
  approve: 'Genehmigen',
  reject: 'Ablehnen',
  changeDecision: 'Entscheidung ändern',
  modal: {
    approve: {
      title: 'Fall genehmigen',
      subtitle: 'Bist du sicher, dass du diesen Fall genehmigen möchtest?',
      confirm: 'Genehmigen'
    },
    revert: {
      title: 'Entscheidung ändern',
      subtitle:
        'Dies ermöglicht es dir, den Fall erneut zu genehmigen oder abzulehnen.',
      confirm: 'Ändern'
    },
    reject: {
      title: 'Fall ablehnen',
      subtitle: 'Bist du sicher, dass du diesen Fall ablehnen möchtest?',
      confirm: 'Ablehnen'
    }
  }
};
