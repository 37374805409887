import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fontsource/rubik';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/600.css';
import '@fontsource/rubik/700.css';
import Intercom from '@intercom/messenger-js-sdk';
import Login from '@/pages/login';
import Mfa from '@/pages/mfa';
import Preapprove from '@/pages/preapprove';
import Signup from '@/pages/signup';
import { flattenMessages, isValidLocale, translationValues } from './intl';
import environmentService from './services/environment.service';

function App() {
  const [cookies, setCookie] = useCookies(['locale']);

  const locale = isValidLocale(cookies.locale) ? cookies.locale : 'en';

  useEffect(() => {
    if (!cookies.locale) setCookie('locale', locale);
  }, [cookies.locale]);

  Intercom({
    app_id: environmentService.intercomAppId,
    language_override: locale
  });

  return (
    <IntlProvider
      locale={locale}
      messages={flattenMessages(translationValues[locale])}>
      <Router>
        <GoogleReCaptchaProvider reCaptchaKey={environmentService.recaptchaKey}>
          <Routes>
            <Route path='/signup/:companyId/:token' element={<Signup />} />
            <Route path='/preapprove/:token' element={<Preapprove />} />
            <Route path='/mfa/:sessionId' element={<Mfa />} />
            <Route path='*' element={<Login />} />
          </Routes>
        </GoogleReCaptchaProvider>
      </Router>
    </IntlProvider>
  );
}

export default App;
