export default {
  title: 'Zwei-Faktor-Authentifizierung',
  subtitle:
    'Bitte gib den Code aus deiner Authenticator-App ein, um den Anmeldeprozess abzuschließen.',
  invalidOtp:
    'Ungültiges Passwort: Du hast noch {retries, plural, one {# Versuch} other {# Versuche}}',
  verify: 'Verifizieren',
  problemsAccessingAuthenticator:
    'Probleme beim Zugriff auf deine Authenticator-App?',
  contactUs: 'Kontaktiere uns hier',
  placeholder: '123456',
  otpTooShort: 'Passwort muss 6 Ziffern lang sein'
};
