export default {
  errors: {
    sessionExpired:
      'Deine Sitzung ist abgelaufen. Melde dich erneut an, um fortzufahren.',
    magicLinkLoginDisabled:
      'Die Anmeldung über einen Magic Link ist für dein Unternehmen deaktiviert. Bitte verwende SSO, um dich anzumelden.',
    userNotFound:
      'Diese E-Mail ist nicht bei Vamoz registriert. Bitte kontaktiere den Administrator deines Unternehmens, um Zugriff zu erhalten.'
  },
  title: {
    checkEmail: 'Überprüfe dein E-Mail-Postfach ✉️',
    emailConfirmed: 'E-Mail bestätigt!',
    welcome: 'Willkommen bei Vamoz!'
  },
  subtitle: {
    emailConfirmed:
      'Deine E-Mail wurde bestätigt. Du kannst dich jetzt bei Vamoz anmelden.',
    enterEmail:
      'Bitte gib deine E-Mail ein, um einen sicheren Login-Link zu erhalten.',
    emailSent:
      'Wir haben dir einen Login-Link per E-Mail geschickt. Es kann ein paar Minuten dauern bis die Mail ankommt. Bitte überprüfe auch deinen Spam-Ordner.'
  },
  button: 'Anmelden',
  dontHaveAccount: 'Noch kein Konto?',
  conactUs: 'Kontaktiere uns hier'
};
