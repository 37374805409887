export default {
  title: 'Pre-approve case',
  reviewCase:
    'Please review the case as described in the corresponding email and click the button below to either approve or reject the case.',
  description:
    '{employeeFirstName} {employeeLastName} is requesting to travel to {country} from {startDate} to {endDate}.',
  thankYou: 'Thank you for your input! You can close this window now.',
  approve: 'Approve',
  reject: 'Reject',
  changeDecision: 'Change decision',
  modal: {
    approve: {
      title: 'Approve case',
      subtitle: 'Are you sure you want to approve this case?',
      confirm: 'Approve'
    },
    revert: {
      title: 'Change decision',
      subtitle: 'This will allow you to approve or reject the case again.',
      confirm: 'Change'
    },
    reject: {
      title: 'Reject case',
      subtitle: 'Are you sure you want to reject this case?',
      confirm: 'Reject'
    }
  }
};
