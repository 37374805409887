import { useCallback, useState } from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useParams, useSearchParams } from 'react-router-dom';
import Jungle from '@/assets/jungle-2.jpg';
import { Button, Heading, Paragraph, Spinner } from '@/components/atoms';
import { AuthForm, Modal } from '@/components/molecules';
import { useIntl } from '@/intl';
import { preapproveCase } from '@/services/auth.service';
import { formatDate } from '@/util/formatDate';

// Example URL:
// /preapprove/fc46a797bfc744b4b879a52579?employeeFirstName=Marc&employeeLastName=Schmidt&country=Austria&city=Vienna&startDate=12-04-2024&endDate=12-28-2024

const Preapprove: React.FC = () => {
  const { formatMessage } = useIntl();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const employeeFirstName = searchParams.get('employeeFirstName');
  const employeeLastName = searchParams.get('employeeLastName');
  const country = searchParams.get('country');
  // const city = searchParams.get('city');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');

  const allParamsGiven =
    employeeFirstName &&
    employeeLastName &&
    country &&
    // city &&
    startDate &&
    endDate;

  const [action, setAction] = useState<'approve' | 'reject' | 'revert'>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  // const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSendRequest = useCallback(async (approvalStatus: string) => {
    // if (!executeRecaptcha) {
    //   console.log('Recaptcha not available');
    //   return;
    // }

    // const recaptchaToken = await executeRecaptcha('preapprove');
    // if (!recaptchaToken) {
    //   return;
    // }

    try {
      setError('');
      setLoading(true);
      await preapproveCase(token ?? '', approvalStatus, '');
      setLoading(false);
      setSuccess(true);
      setShowModal(false);
    } catch {
      setLoading(false);
      setError(formatMessage({ id: 'shared.errors.generic' }));
    }
  }, []);

  const handleReject = () => {
    void handleSendRequest('REJECT');
  };

  const handleApprove = () => {
    void handleSendRequest('APPROVE');
  };

  const revertDecision = () => {
    setError('');
    setSuccess(false);
  };

  return (
    <>
      <AuthForm
        onSubmit={e => {
          e.preventDefault();
        }}
        imageSrc={Jungle}>
        <Heading type='h2'>{formatMessage({ id: 'preapprove.title' })}</Heading>

        {!success ? (
          <Paragraph>
            {!allParamsGiven
              ? formatMessage({ id: 'preapprove.reviewCase' })
              : formatMessage(
                  { id: 'preapprove.description' },
                  {
                    employeeFirstName,
                    employeeLastName,
                    country,
                    // city,
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate)
                  }
                )}
          </Paragraph>
        ) : (
          <Paragraph>{formatMessage({ id: 'preapprove.thankYou' })}</Paragraph>
        )}

        {success ? (
          <Button
            variant='gray'
            onClick={() => {
              setAction('revert');
              setShowModal(true);
            }}>
            {formatMessage({ id: 'preapprove.changeDecision' })}
          </Button>
        ) : (
          <div className='flex w-full flex-col gap-4 sm:flex-row sm:justify-center md:justify-start'>
            <Button
              variant='success'
              onClick={() => {
                setAction('approve');
                setShowModal(true);
              }}>
              {formatMessage({ id: 'preapprove.approve' })}
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setAction('reject');
                setShowModal(true);
              }}>
              {formatMessage({ id: 'preapprove.reject' })}
            </Button>
          </div>
        )}
      </AuthForm>

      <Modal
        title={
          action === 'approve'
            ? formatMessage({ id: 'preapprove.modal.approve.title' })
            : action === 'revert'
            ? formatMessage({ id: 'preapprove.modal.revert.title' })
            : formatMessage({ id: 'preapprove.modal.reject.title' })
        }
        subtitle={
          action === 'approve'
            ? formatMessage({ id: 'preapprove.modal.approve.subtitle' })
            : action === 'revert'
            ? formatMessage({ id: 'preapprove.modal.revert.subtitle' })
            : formatMessage({ id: 'preapprove.modal.reject.subtitle' })
        }
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setError('');
        }}
        onConfirm={() => {
          if (action === 'approve') {
            handleApprove();
          } else if (action === 'revert') {
            revertDecision();
          } else {
            handleReject();
          }
          if (success) {
            setShowModal(false);
          }
        }}
        confirmButtonText={
          action === 'approve'
            ? formatMessage({ id: 'preapprove.modal.approve.confirm' })
            : action === 'revert'
            ? formatMessage({ id: 'preapprove.modal.revert.confirm' })
            : formatMessage({ id: 'preapprove.modal.reject.confirm' })
        }
        confirmButtonVariant={
          action === 'approve'
            ? 'success'
            : action === 'revert'
            ? 'secondary'
            : 'danger'
        }
        noAction={loading}
        errorMessage={error}>
        {loading && (
          <div className='mx-auto pt-2'>
            <Spinner />
          </div>
        )}
      </Modal>
    </>
  );
};

export default Preapprove;
