export default {
  locale: {
    en: 'English',
    de: 'German'
  },
  placeholders: {
    companyEmail: 'johndoe@acme.com'
  },
  errors: {
    generic: 'An unexpected error occurred, please try again.',
    invalidEmail: 'Please provide a valid email address'
  },
  actions: {
    cancel: 'Cancel',
    confirm: 'Confirm'
  }
};
