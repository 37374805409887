import type { FC, DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface ParagraphProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary';
}

const paragraphClasses = (
  size: ParagraphProps['size'],
  variant: ParagraphProps['variant'],
  customClasses: string
) =>
  twMerge(
    clsx(
      {
        'text-sm': size === 'sm',
        'text-base': size === 'md',
        'text-lg': size === 'lg',
        'text-gray-500': variant === 'secondary',
        'text-black': variant === 'primary'
      },
      customClasses
    )
  );

export const Paragraph: FC<ParagraphProps> = ({
  children,
  size = 'md',
  variant = 'primary',
  className: customClasses
}) => (
  <p className={paragraphClasses(size, variant, customClasses ?? '')}>
    {children}
  </p>
);
