export default {
  title: 'Authentification à deux facteurs',
  subtitle:
    'Veuillez entrer le mot de passe à usage unique de votre application Authenticator pour compléter le processus de connexion.',
  invalidOtp:
    'OTP invalide : Il vous reste {retries, plural, one {# tentative} other {# tentatives}}',
  verify: 'Vérifier',
  problemsAccessingAuthenticator:
    'Des problèmes pour accéder à votre application Authenticator ?',
  contactUs: 'Contactez-nous ici',
  placeholder: '123456',
  otpTooShort: 'Le OTP doit être de 6 chiffres'
};
