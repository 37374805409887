import { twMerge } from 'tailwind-merge';
import Jungle from '@/assets/jungle-1.jpg';

interface AuthFormProps
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  imageSrc?: string;
}

export const AuthForm: React.FC<AuthFormProps> = ({
  children,
  className,
  imageSrc,
  ...props
}) => (
  <div className='relative grid h-screen w-screen lg:grid-cols-2 xl:grid-cols-[2fr_2.5fr]'>
    <form
      {...props}
      className={twMerge(
        'mx-auto flex h-full w-full max-w-md flex-col justify-center gap-4 px-6 text-center text-white lg:text-start lg:text-inherit [&_input]:bg-gray-200/90 lg:[&_input]:bg-gray-200/50 [&_p]:text-white lg:[&_p]:text-black',
        className
      )}>
      {children}
      {/* <LanguageSwitch /> */}
    </form>

    <div className='absolute inset-0 -z-10 lg:relative lg:inset-auto lg:block lg:p-4'>
      <div
        style={{ backgroundImage: `url(${imageSrc ?? Jungle})` }}
        className='h-full w-full bg-cover bg-center lg:rounded-2xl xl:rounded-3xl'
      />
      <div className='absolute inset-0 z-10 bg-gradient-to-br from-black/50 to-black/20 lg:inset-4 lg:rounded-2xl lg:bg-gradient-to-t lg:from-primary/10 lg:to-transparent xl:rounded-3xl' />
    </div>
  </div>
);
