export default {
  title: 'Pré-approuver le cas',
  reviewCase:
    "Veuillez examiner le cas comme décrit dans l'email correspondant et cliquez sur le bouton ci-dessous pour approuver ou rejeter le cas.",
  description:
    '{employeeFirstName} {employeeLastName} demande à voyager vers {country} du {startDate} au {endDate}.',
  thankYou:
    'Merci pour votre contribution ! Vous pouvez fermer cette fenêtre maintenant.',
  approve: 'Approuver',
  reject: 'Rejeter',
  changeDecision: 'Changer de décision',
  modal: {
    approve: {
      title: 'Approuver le cas',
      subtitle: 'Êtes-vous sûr de vouloir approuver ce cas ?',
      confirm: 'Approuver'
    },
    revert: {
      title: 'Changer de décision',
      subtitle:
        "Cela vous permettra d'approuver ou de rejeter à nouveau le cas.",
      confirm: 'Changer'
    },
    reject: {
      title: 'Rejeter le cas',
      subtitle: 'Êtes-vous sûr de vouloir rejeter ce cas ?',
      confirm: 'Rejeter'
    }
  }
};
