export default {
  title: 'Two-factor authentication',
  subtitle:
    'Please enter the one-time password from your Authenticator app to complete the login process.',
  invalidOtp:
    'Invalid OTP: You have {retries, plural, one {# attempt} other {# attempts}} left',
  verify: 'Verify',
  problemsAccessingAuthenticator: 'Problems accessing your Authenticator app?',
  contactUs: 'Contact us here',
  placeholder: '123456',
  otpTooShort: 'OTP must be 6 digits long'
};
