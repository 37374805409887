import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { BiHelpCircle } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Heading,
  Paragraph,
  Spinner,
  TextInput
} from '@/components/atoms';
import {
  AuthForm,
  Modal,
  SignupInstructions,
  SuccessMessage
} from '@/components/molecules';
import { useIntl } from '@/intl';
import type { CompanyData } from '@/services/auth.service';
import { fetchCompanyData, signup } from '@/services/auth.service';
import { convertToUUID } from '@/util';

const Signup: React.FC = () => {
  const { formatMessage } = useIntl();
  const { companyId: cId, token } = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showInstructions, setShowInstructions] = useState(false);
  const [showGoToLogin, setShowGoToLogin] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyData>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();
  const [email, setEmail] = useState('');

  const companyId = convertToUUID(cId ?? '');

  const loadCompanyData = async () => {
    try {
      setLoading(true);
      const companyData = await fetchCompanyData(companyId, token ?? '');
      setCompanyData(companyData);
    } catch {
      setError(formatMessage({ id: 'shared.errors.generic' }));
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = useCallback(async () => {
    try {
      if (!executeRecaptcha) throw new Error('Recaptcha not loaded');

      const recaptchaToken = await executeRecaptcha('signup');
      if (!recaptchaToken) throw new Error('Recaptcha token not found');

      setLoading(true);
      const status = await signup(
        email,
        companyId,
        token ?? '',
        recaptchaToken
      );

      switch (status) {
        case 302:
          setShowGoToLogin(true);
          setError(
            formatMessage({ id: 'signup.errors.emailAlreadyRegistered' })
          );
          break;
        case 200:
          setSuccess(true);
          break;
        default:
          setError(formatMessage({ id: 'signup.errors.invalidEmail' }));
          break;
      }
    } catch {
      setError(formatMessage({ id: 'shared.errors.generic' }));
    } finally {
      setLoading(false);
    }
  }, [email, executeRecaptcha]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.length === 0) {
      setError(formatMessage({ id: 'shared.errors.invalidEmail' }));
      return;
    }

    void handleSignup();
  };

  useEffect(() => {
    void loadCompanyData();
  }, []);

  return (
    <>
      <AuthForm onSubmit={handleSubmit}>
        <Heading type='h2'>
          {companyData?.company_name}
          <br />
          {formatMessage({ id: 'signup.title' })}
        </Heading>

        <Paragraph>
          {companyData?.welcome
            ? companyData?.welcome
            : formatMessage({ id: 'signup.subtitle' })}
        </Paragraph>

        {loading ? (
          <div className='flex justify-center lg:justify-start'>
            <Spinner />
          </div>
        ) : success ? (
          <SuccessMessage
            title={formatMessage({ id: 'signup.success.title' })}
            message={formatMessage({ id: 'signup.success.message' })}
          />
        ) : showGoToLogin ? (
          <>
            {error && <Paragraph className='!text-red'>{error}</Paragraph>}

            <Link to='/' className='w-full'>
              <Button>{formatMessage({ id: 'signup.goToLogin' })}</Button>
            </Link>
          </>
        ) : (
          <>
            <TextInput
              type='email'
              id='email'
              name='email'
              placeholder={formatMessage({
                id: 'shared.placeholders.companyEmail'
              })}
              value={email}
              error={!!error}
              onChange={e => {
                setEmail(e.target.value);
              }}
            />

            {error && <Paragraph className='!text-red'>{error}</Paragraph>}

            <Button type='submit' variant='primary'>
              {formatMessage({ id: 'signup.button' })}
            </Button>

            {/* <Paragraph>
              {formatMessage(
                { id: 'signup.allowedDomains' },
                {
                  domains: companyData?.valid_domains.join(', ')
                }
              )}
            </Paragraph> */}

            {!success && companyData?.description && (
              <div>{companyData?.description}</div>
            )}

            <button
              type='button'
              className='flex items-center gap-1 self-center underline outline-none lg:self-start'
              onClick={() => {
                setShowInstructions(!showInstructions);
              }}>
              <BiHelpCircle />
              {formatMessage({ id: 'signup.help' })}
            </button>
          </>
        )}
      </AuthForm>

      <Modal
        title={formatMessage({ id: 'signup.instructions.title' })}
        subtitle={formatMessage({ id: 'signup.instructions.subtitle' })}
        isOpen={showInstructions}
        onClose={() => {
          setShowInstructions(false);
        }}
        noAction>
        <SignupInstructions />
      </Modal>
    </>
  );
};

export default Signup;
