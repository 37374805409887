export default {
  locale: {
    en: 'Anglais',
    de: 'Allemand'
  },
  placeholders: {
    companyEmail: 'johndoe@acme.com'
  },
  errors: {
    generic: 'Une erreur inattendue est survenue, veuillez réessayer.',
    invalidEmail: 'Veuillez fournir une adresse email valide'
  },
  actions: {
    cancel: 'Annuler',
    confirm: 'Confirmer'
  }
};
