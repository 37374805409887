export default {
  errors: {
    emailAlreadyRegistered: 'Email già registrata, prova ad accedere.',
    invalidEmail: 'Email non valida, riprova.'
  },
  title: 'Registrati',
  subtitle: 'Crea un nuovo account Vamoz',
  success: {
    title: 'Controlla la tua email ✉️',
    message:
      "Ti abbiamo inviato un'email con un link per verificare il tuo account. L'email potrebbe impiegare alcuni minuti per arrivare. Controlla anche la cartella spam."
  },
  goToLogin: 'Vai al login',
  button: 'Registrati',
  allowedDomains: 'Domini consentiti: {domains}',
  help: 'Aiuto',
  instructions: {
    title: 'Istruzioni per la registrazione',
    subtitle: 'Segui questi tre passaggi per registrarti su Vamoz.',
    step1: 'Inserisci il tuo indirizzo email (da un dominio consentito)',
    step2:
      'Controlla la tua email (potrebbe richiedere alcuni minuti per arrivare)',
    step3: "Clicca sul link nell'email per confermare il tuo indirizzo email",
    spamFolder:
      "Se non vedi l'email nella tua casella di posta, controlla la cartella spam."
  }
};
