export default {
  errors: {
    sessionExpired:
      'La tua sessione è scaduta. Effettua nuovamente il login per continuare.',
    magicLinkLoginDisabled:
      'Il login tramite link magico è disabilitato per la tua azienda. Utilizza invece il SSO per effettuare il login.',
    userNotFound:
      "Questa email non è registrata con Vamoz. Contatta l'amministratore della tua azienda per ottenere l'accesso."
  },
  title: {
    checkEmail: 'Controlla la tua email ✉️',
    emailConfirmed: 'Email confermata!',
    welcome: 'Benvenuto su Vamoz!'
  },
  subtitle: {
    emailConfirmed:
      'La tua email è stata confermata. Ora puoi accedere a Vamoz.',
    enterEmail:
      'Inserisci la tua email per ricevere un link di accesso sicuro.',
    emailSent:
      "Ti abbiamo inviato un'email con un link di accesso sicuro per accedere a Vamoz. L'email potrebbe impiegare alcuni minuti per arrivare. Controlla anche la cartella spam."
  },
  button: 'Login',
  dontHaveAccount: 'Non hai ancora un account?',
  conactUs: 'Contattaci qui'
};
