import de from './de';
import en from './en';
import fr from './fr';
import it from './it';

export const LOCALES = ['en', 'de', 'fr', 'it'] as const;
export const DEFAULT_LOCALE: Locale = 'en' as const;

export type Locale = (typeof LOCALES)[number];

export const isValidLocale = (locale: any): locale is Locale =>
  LOCALES.includes(locale);

export const translationValues: Record<Locale, typeof en> = {
  de,
  en,
  fr,
  it
};
