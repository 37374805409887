import type { FC } from 'react';

interface SuccessMessageProps {
  title: string;
  message: string;
}

export const SuccessMessage: FC<SuccessMessageProps> = ({ title, message }) => (
  <div className='flex w-full flex-col gap-6'>
    <p className='bg-green/90 rounded-xl p-6'>{title}</p>
    <p className=' leading-relaxed'>{message}</p>
  </div>
);
