export default {
  errors: {
    emailAlreadyRegistered: 'Email already registered, please try logging in.',
    invalidEmail: 'Invalid email, please try again.'
  },
  title: 'Sign up',
  subtitle: 'Create a new Vamoz account',
  success: {
    title: 'Check your email ✉️',
    message:
      "We've sent you an email with a link to verify your account. The email may take a few minutes to arrive. Please also check your spam folder."
  },
  goToLogin: 'Go to login',
  button: 'Signup',
  allowedDomains: 'Allowed domains: {domains}',
  help: 'Help',
  instructions: {
    title: 'Signup Instructions',
    subtitle: 'Please follow these three steps to sign up to Vamoz.',
    step1: 'Enter your email address (from an allowed domain)',
    step2: 'Check your email (may take a few minutes to arrive)',
    step3: 'Click the link in the email to confirm your email address',
    spamFolder:
      "If you don't see the email in your inbox, please check your spam folder."
  }
};
