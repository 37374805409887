import login from './login';
import mfa from './mfa';
import preapprove from './preapprove';
import shared from './shared';
import signup from './signup';

export default {
  login,
  mfa,
  preapprove,
  shared,
  signup
};
