import type { FC, AnchorHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface StyledLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  variant?: 'default' | 'highlighted';
  children: ReactNode;
}

export const StyledLink: FC<StyledLinkProps> = ({
  children,
  className,
  variant = 'default',
  ...props
}) => (
  <a
    className={twMerge(
      clsx('no-underline transition-opacity duration-200 hover:opacity-80', {
        'text-primary': variant === 'highlighted'
      }),
      className
    )}
    {...props}>
    {children}
  </a>
);
