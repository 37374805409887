export default {
  errors: {
    sessionExpired:
      'Votre session a expiré. Connectez-vous à nouveau pour continuer.',
    magicLinkLoginDisabled:
      'La connexion par lien magique est désactivée pour votre entreprise. Veuillez utiliser SSO pour vous connecter à la place.',
    userNotFound:
      "Cet email n'est pas enregistré chez Vamoz. Veuillez contacter votre administrateur pour obtenir l'accès."
  },
  title: {
    checkEmail: 'Vérifiez votre email ✉️',
    emailConfirmed: 'Email confirmé !',
    welcome: 'Bienvenue chez Vamoz !'
  },
  subtitle: {
    emailConfirmed:
      'Votre email a été confirmé. Vous pouvez maintenant vous connecter à Vamoz.',
    enterEmail:
      'Veuillez entrer votre email pour recevoir un lien de connexion sécurisé.',
    emailSent:
      "Nous vous avons envoyé un email avec un lien de connexion sécurisé pour vous connecter à Vamoz. L'email peut prendre quelques minutes pour arriver. Veuillez également vérifier votre dossier spam."
  },
  button: 'Connexion',
  dontHaveAccount: "Vous n'avez pas encore de compte ?",
  conactUs: 'Contactez-nous ici'
};
