export default {
  title: 'Pre-approva il caso',
  reviewCase:
    "Per favore esamina il caso come descritto nell'email corrispondente e clicca sul pulsante qui sotto per approvare o rifiutare il caso.",
  description:
    '{employeeFirstName} {employeeLastName} sta richiedendo di viaggiare verso {country} dal {startDate} al {endDate}.',
  thankYou: 'Grazie per il tuo contributo! Ora puoi chiudere questa finestra.',
  approve: 'Approva',
  reject: 'Rifiuta',
  changeDecision: 'Cambia decisione',
  modal: {
    approve: {
      title: 'Approva il caso',
      subtitle: 'Sei sicuro di voler approvare questo caso?',
      confirm: 'Approva'
    },
    revert: {
      title: 'Cambia decisione',
      subtitle:
        'Questo ti permetterà di approvare o rifiutare nuovamente il caso.',
      confirm: 'Cambia'
    },
    reject: {
      title: 'Rifiuta il caso',
      subtitle: 'Sei sicuro di voler rifiutare questo caso?',
      confirm: 'Rifiuta'
    }
  }
};
