import type { IntlFormatters } from 'react-intl';
import { useIntl as useReactIntl } from 'react-intl';
import type { KeyPaths } from '.';
import type en from '../locales/en';

type IntlMessageKeys = KeyPaths<typeof en>;

type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;

export type FormatMessageType = (
  descriptor: FormatMessageArgs[0] & {
    id?: IntlMessageKeys;
  },
  values?: FormatMessageArgs[1],
  options?: FormatMessageArgs[2]
) => string;

export const useIntl = () => {
  // Pull out the original formatMessage function
  const { formatMessage, ...rest } = useReactIntl();

  // Rewrite the formatMessage function with an enhanced id type
  const typedFormatMessage = (
    descriptor: FormatMessageArgs[0] & {
      id?: IntlMessageKeys;
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2]
  ) => formatMessage(descriptor, values as any, options);

  return {
    ...rest,
    formatMessage: typedFormatMessage
  };
};
