import type en from '../locales/en';

type Copy = typeof en;

export type KeyPaths<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends Record<string, unknown>
    ? `${K & string}.${KeyPaths<T[K]> & string}`
    : K;
}[keyof T] extends infer O
  ? { [K in keyof O]: O[K] }
  : never;

export type TranslationValues = Record<KeyPaths<Copy>, string>;

export const flattenMessages = (
  nestedMessages: any,
  prefix = ''
): TranslationValues => {
  if (!nestedMessages) throw new Error('No messages provided');

  return Object.keys(nestedMessages).reduce<any>((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};
