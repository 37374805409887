export default {
  locale: {
    en: 'Englisch',
    de: 'Deutsch'
  },
  placeholders: {
    companyEmail: 'johndoe@acme.com'
  },
  errors: {
    generic:
      'Ein unerwarteter Fehler ist aufgetreten, bitte versuche es erneut.',
    invalidEmail: 'Bitte gib eine gültige E-Mail-Adresse an'
  },
  actions: {
    cancel: 'Abbrechen',
    confirm: 'Bestätigen'
  }
};
