export default {
  title: 'Autenticazione a due fattori',
  subtitle:
    'Inserisci la password monouso dalla tua app Authenticator per completare il processo di login.',
  invalidOtp:
    'OTP non valido: hai {retries, plural, one {# tentativo} other {# tentativi}} rimasti',
  verify: 'Verifica',
  problemsAccessingAuthenticator:
    'Problemi ad accedere alla tua app Authenticator?',
  contactUs: 'Contattaci qui',
  placeholder: '123456',
  otpTooShort: "L'OTP deve essere lungo 6 cifre"
};
