import type { ButtonHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  center?: boolean;
  children: ReactNode;
  variant?:
    | 'secondary'
    | 'primary'
    | 'success'
    | 'danger'
    | 'transparent'
    | 'gray'
    | 'outline';
}

const baseClasses =
  'flex items-center justify-center gap-2 rounded-xl px-6 py-2 text-center font-normal text-white outline-none transition-all duration-150 hover:opacity-90 shrink-0';
export const disabledClasses = 'cursor-not-allowed opacity-70 hover:opacity-70';

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'secondary',
  center = false,
  disabled,
  className,
  ...props
}) => (
  <button
    className={twMerge(
      baseClasses,
      clsx({
        'mx-auto': center,
        'bg-secondary': variant === 'secondary',
        'bg-primary': variant === 'primary',
        'bg-green-500': variant === 'success',
        'bg-red': variant === 'danger',
        'bg-gray-200 text-black': variant === 'gray',
        'bg-transparent': variant === 'transparent',
        'border border-gray-200 bg-transparent text-black hover:bg-gray-100':
          variant === 'outline'
      }),
      disabled && disabledClasses,
      className
    )}
    disabled={disabled}
    {...props}>
    {children}
  </button>
);
