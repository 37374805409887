import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useParams, useSearchParams } from 'react-router-dom';
import Jungle from '@/assets/jungle-2.jpg';
import { Button, Heading, Paragraph, StyledLink } from '@/components/atoms';
import { TextInput } from '@/components/atoms/TextInput';
import { AuthForm } from '@/components/molecules';
import { useIntl } from '@/intl';
import environmentService from '@/services/environment.service';

const MFA: React.FC = () => {
  const { sessionId } = useParams();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [otp, setOtp] = useState('');
  const [internalError, setInternalError] = useState<string>();

  const error = searchParams.get('error');
  const retries = Number(searchParams.get('retries')) || 0;

  const handleMfaInput = useCallback(async () => {
    try {
      if (!executeRecaptcha) throw new Error('Recaptcha not loaded');

      const recaptchaToken = await executeRecaptcha('mfa');
      if (!recaptchaToken) throw new Error('Recaptcha token not found');

      setInternalError(undefined);
      const otpSubmitUrl = `${environmentService.baseUrl}/auth/v2/secure_tmp/otp`;
      const data = {
        session_id: sessionId,
        otp,
        recaptcha_token: recaptchaToken
      };

      const response = await fetch(otpSubmitUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Invalid OTP');

      // Redirect based on backend response (if needed)
      const responseData = await response.json();
      if (responseData?.redirect_url) {
        window.location.href = responseData.redirect_url;
      }
    } catch (error) {
      setInternalError(formatMessage({ id: 'shared.errors.generic' }));
    }
  }, [otp]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault(); // Prevent the default form submission

    if (otp.length !== 6) {
      setInternalError(
        formatMessage({
          id: 'mfa.otpTooShort'
        })
      );
      return;
    }

    void handleMfaInput();
  };

  return (
    <AuthForm onSubmit={handleSubmit} imageSrc={Jungle}>
      <Heading type='h1'>
        {formatMessage({
          id: 'mfa.title'
        })}
      </Heading>

      <Paragraph>
        {formatMessage({
          id: 'mfa.subtitle'
        })}
      </Paragraph>

      <div className='flex items-stretch gap-2'>
        <TextInput
          type='text'
          id='otp'
          name='otp'
          value={otp}
          error={!!internalError || !!error}
          onChange={e => {
            setOtp(e.target.value);
          }}
          placeholder={formatMessage({
            id: 'mfa.placeholder'
          })}
          maxLength={6}
        />
        <Button type='submit'>
          {formatMessage({
            id: 'mfa.verify'
          })}
        </Button>
      </div>

      {(error ?? internalError) && (
        <Paragraph className='!text-red'>
          {internalError ??
            (error === 'invalid_code' &&
              formatMessage(
                {
                  id: 'mfa.invalidOtp'
                },
                {
                  retries
                }
              ))}
        </Paragraph>
      )}

      <Paragraph>
        {formatMessage({
          id: 'mfa.problemsAccessingAuthenticator'
        })}{' '}
        <StyledLink href='mailto:support@vamoz.io' variant='highlighted'>
          {formatMessage({
            id: 'mfa.contactUs'
          })}
        </StyledLink>
      </Paragraph>
    </AuthForm>
  );
};

export default MFA;
