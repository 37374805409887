export default {
  errors: {
    emailAlreadyRegistered:
      'Email déjà enregistré, veuillez essayer de vous connecter.',
    invalidEmail: 'Email invalide, veuillez réessayer.'
  },
  title: 'Inscription',
  subtitle: 'Créez un nouveau compte Vamoz',
  success: {
    title: 'Vérifiez votre email ✉️',
    message:
      "Nous vous avons envoyé un email avec un lien pour vérifier votre compte. L'email peut prendre quelques minutes pour arriver. Veuillez également vérifier votre dossier spam."
  },
  goToLogin: 'Aller à la connexion',
  button: "S'inscrire",
  allowedDomains: 'Domaines autorisés : {domains}',
  help: 'Aide',
  instructions: {
    title: "Instructions d'inscription",
    subtitle: 'Veuillez suivre ces trois étapes pour vous inscrire à Vamoz.',
    step1: "Entrez votre adresse email (d'un domaine autorisé)",
    step2:
      'Vérifiez votre email (cela peut prendre quelques minutes pour arriver)',
    step3:
      "Cliquez sur le lien dans l'email pour confirmer votre adresse email",
    spamFolder:
      "Si vous ne voyez pas l'email dans votre boîte de réception, veuillez vérifier votre dossier spam."
  }
};
