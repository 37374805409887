import type { FC, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: boolean;
}

export const baseInputClasses =
  'w-full appearance-none rounded-xl border-2 border-gray-200/50 bg-gray-200/50 px-4 py-3 text-base leading-none text-black outline-none transition-all duration-200 placeholder:text-black/30 focus:border-primary';
export const disabledInputClasses =
  'cursor-not-allowed bg-gray-200 border-gray-200 text-gray-400 focus:border-gray-200';
export const errorInputClasses =
  'border-red/75 focus:border-red/75 bg-red/10 placeholder:text-red/75';

export const TextInput: FC<TextInputProps> = ({
  className,
  error,
  disabled,
  ...props
}) => (
  <input
    type='text'
    disabled={disabled}
    {...props}
    className={twMerge(
      baseInputClasses,
      disabled && disabledInputClasses,
      error && errorInputClasses,
      className ?? ''
    )}
  />
);
